(function (angular, _) {
    const  MyHippoFormlyDirectives = angular.module('MyHippoFormly.Directives');
    MyHippoFormlyDirectives.directive('mhMortgagesList', mhMortgagesList);

    function mhMortgagesList (toaster) {
        return {
            restrict: 'E',
            templateUrl: 'common/formly-directives/mortgages-list/template.html',
            wrapper: [],
            overwriteOk: true,
            controller: function ($scope, $mdDialog, toaster) {
                const policyId = _.get($scope.model, 'id');
                const availableTypes = ['first', 'second', 'third'];
                $scope.isEscrowCheckout = _.get($scope.model, 'checkout_data.payment_method') === 'escrow';
                $scope.mortgages = _.get($scope.model, 'property_data.mortgages.details', []);

                $scope.editMortgage = (mortgage) => {
                    $mdDialog.show({
                        template: '<mh-update-mortgage-modal mortgage="mortgage" policy-id="policyId" is-escrow-checkout="isEscrowCheckout"></mh-update-mortgage-modal>',
                        parent: angular.element(document.body),
                        locals: { mortgage, policyId, isEscrowCheckout: $scope.isEscrowCheckout },
                        controller: ($scope, mortgage, policyId, isEscrowCheckout) => {
                            $scope.policyId = policyId;
                            $scope.mortgage = mortgage;
                            $scope.isEscrowCheckout = isEscrowCheckout;
                        }
                    }).then((updatedPolicy) => {
                        $scope.mortgages = _.get(updatedPolicy, 'property_data.mortgages.details', []);
                    });
                };

                $scope.deleteMortgage = (mortgage) => {
                    $mdDialog.show({
                        template: '<mh-delete-mortgage-modal mortgage="mortgage" policy-id="policyId"></mh-delete-mortgage-modal>',
                        parent: angular.element(document.body),
                        locals: { policyId, mortgage },
                        controller: ($scope, policyId, mortgage) => {
                            $scope.policyId = policyId;
                            $scope.mortgage = mortgage;
                        }
                    }).then((updatedPolicy) => {
                        $scope.mortgages = _.get(updatedPolicy, 'property_data.mortgages.details', []);
                    });
                };

                $scope.addMortgage = () => {
                    const createableTypes = availableTypes.filter(t => !$scope.mortgages.find(mortgage => mortgage.type === t));
                    if ($scope.mortgages.length < 3 && createableTypes.length) {
                        $mdDialog.show({
                            template: `<mh-add-mortgage-modal policy-id="policyId" type="type"></mh-add-mortgage-modal>`,
                            parent: angular.element(document.body),
                            locals: { policyId },
                            controller: ($scope, policyId) => {
                                $scope.policyId = policyId;
                                $scope.type = createableTypes[0];
                            }
                        }).then((updatedPolicy) => {
                            $scope.mortgages = _.get(updatedPolicy, 'property_data.mortgages.details', []);
                        });
                    } else {
                        toaster.pop('error', 'Unable to add anymore mortgages (Max 3)');
                    }
                };
            }
        };
    }
})(window.angular, window._);
